import React from "react"
import PropTypes from "prop-types"
import { Container } from "../../Helpers/Container"
import { Icon } from "antd"
import axios from "axios"
import "./styles.less"
import { GoogleApiWrapper } from "google-maps-react"

const mapDays = ["Mon", "Tue", "Wed", "Thu", "Fr", "Sat", "Sun"]

const MainElement = ({ openingHours, description }) => {
  const d = new Date()
  const today = d.getDay() === 0 ? 6 : d.getDay() - 1
  // 0 = sunday
  // 1 = monday
  const isOpenNow = openingHours && openingHours.isOpen(d)

  // today - 1 > sunday

  return (
    <div className="main">
      <img className="logo-img" src="/img/krom_logo.png" />
      <div style={{ margin: "30px 0 0" }} />
      {openingHours && (
        <div className="open-now">
          {isOpenNow ? (
            <span>
              <Icon type="check-circle" theme="filled" /> We are open!
            </span>
          ) : (
            <span>
              <Icon type="close-circle" theme="filled" /> We are not open now!
            </span>
          )}
        </div>
      )}
      <div>
        <div>
          {openingHours &&
            openingHours.periods &&
            openingHours.periods.map((elem, i) => {
              const openTime = [
                elem.open.time.slice(0, 2),
                ".",
                elem.open.time.slice(2)
              ].join("")
              const closeTime = [
                elem.close.time.slice(0, 2),
                ".",
                elem.close.time.slice(2)
              ].join("")
              return (
                <div
                  className={`hour-elem ${
                    i === today && isOpenNow ? "active" : " "
                  }`}
                  key={`hour-${i}`}
                >
                  <span>
                    <span>
                      {mapDays[i]} | {openTime} - {closeTime}
                    </span>
                  </span>
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}

class HomePageTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openingHours: null
    }
  }

  componentDidMount() {
    if (this.props.google) {
      const service = new this.props.google.maps.places.PlacesService(
        document.createElement("div")
      )
      if (service) {
        const request = {
          placeId: "ChIJzf2EAgVOqEcRZOvtbKGQ4to",
          fields: ["opening_hours", "utc_offset_minutes"]
        }
        service.getDetails(request, res => {
          const openingHours = res.opening_hours
          this.setState({ openingHours: openingHours })
        })
      }
    }
  }

  render() {
    const { title, description, address } = this.props
    return (
      <div className="home-page">
        <div id="map" />
        <Container>
          <MainElement
            description={description}
            address={address}
            openingHours={this.state.openingHours}
          />
        </Container>
      </div>
    )
  }
}

HomePageTemplate.propTypes = {
  title: PropTypes.string.isRequired
}

export default GoogleApiWrapper({
  apiKey: process.env.GATSBY_PLACES_API_KEY
})(HomePageTemplate)
